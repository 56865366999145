import { FC } from 'react';
import classNames from 'classnames';
import { SwiperProps, SwiperSlide } from 'swiper/react';

import { Carousel, CarouselProps } from 'components/common/Carousel';
import { CloudinaryImage } from 'components/common/CloudinaryImage';
import { Link } from 'components/common/Link';
import { StyledComponent } from 'types';

import { FeaturedPressItem } from './consts';
import styles from './FeaturedPress.module.scss';

interface FeaturedPressCarouselProps extends StyledComponent, CarouselProps {
  items: FeaturedPressItem[];
  itemClassname?: string;
}

const defaultSwiperProps: SwiperProps = {
  className: 'w-100',
  spaceBetween: 15,
  loop: true,
  slidesPerGroupAuto: false,
  autoplay: {
    delay: 0.001,
    disableOnInteraction: false
  },
  speed: 7500,
  updateOnWindowResize: false
};

export const FeaturedPressCarousel: FC<FeaturedPressCarouselProps> = ({
  items,
  className,
  itemClassname,
  ...props
}) => (
  <Carousel
    showArrowGradient
    disableArrows
    className={classNames(className, styles['linear-transition'])}
    swiperProps={defaultSwiperProps}
    {...props}
  >
    {
      // duplicated items so there is enough items to prevent glitch on large screens
      items.concat(items).map((item, index) => (
        <SwiperSlide
          className={classNames(
            'd-flex align-items-center justify-content-center px-2 px-md-0',
            styles['featured-press-slide'],
            itemClassname
          )}
          key={item.logo.alt + index}
          data-testid='swiper-slide'
        >
          <Link href={item.href} target='_blank'>
            <CloudinaryImage priority {...item.logo} />
          </Link>
        </SwiperSlide>
      ))
    }
  </Carousel>
);
